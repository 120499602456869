// Modules
import React from "react";
import { graphql } from "gatsby";

// Components
import BlockContent from "../../components/richText";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import Layout from "../../components/layout";
import PageBuilder from "../../components/pageBuilder";
import SideMenu from "../../components/sideMenu";
import Seo from "../../components/seo/seo";

// Helpers
import createBreadcrumbs from "../../helpers/createBreadcrumbs";
import { getVideoDataInPage } from "../../helpers/getVideoDataInPage";

// Styles
import * as styles from "./successFactor.module.scss";
import * as blogStyles from "../blogsStyles.module.scss";

const SuccessFactors = ({ data, pageContext }) => {
  const { allSanityNav, sanitySfPage } = data;

  const { pageBuilder, pageHero, pageRichText } = sanitySfPage.tabs._rawContent;

  const content = data?.sanitySfPage?._rawTabs?.content;
  const seo = sanitySfPage.tabs?._rawSeo;
  const heroTitle = content.pageHero.heroText.titleText;
  const { _createdAt, _updatedAt } = sanitySfPage;
  const HeroComponent = content.pageHero?.isRedesigned ? HeroRedesigned : Hero;
  const video =
    getVideoDataInPage(pageBuilder) || getVideoDataInPage(pageRichText);

  const breadcrumbsList = createBreadcrumbs(content);

  const asideMenuLinks =
    allSanityNav.nodes[0]._rawMainNavLinkItem[0].leftCol.columnLink;

  const pageBuilderClasses = {
    textImage: styles.pageBuilderTextImage,
  };

  return (
    <Layout stickyHeader isPage>
      <Seo
        title={heroTitle}
        image={pageHero?.bgImage}
        breadcrumbs={breadcrumbsList}
        path={pageContext?.pagePath}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        video={video}
        {...seo}
      />
      <HeroComponent
        imageClassName={blogStyles.heroImg}
        breadcrumbs={breadcrumbsList}
        image={pageHero.bgImage}
        subTitle={pageHero?.heroText.subtitleText}
        title={pageHero.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div>
          <SideMenu list={asideMenuLinks} />
        </div>
        <div className={styles.richText}>
          {pageRichText && (
            <BlockContent
              blocks={pageRichText}
              customClassName={styles.gridImgTextContainer}
            />
          )}
        </div>
      </div>
      <div className={styles.pageBuilder}>
        <PageBuilder
          classNamesObj={pageBuilderClasses}
          pageBuilderContents={pageBuilder}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanitySfPage(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _createdAt
      _updatedAt
      tabs {
        _rawSeo
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      _rawTabs(resolveReferences: { maxDepth: 10 })
    }
    allSanityNav {
      nodes {
        _rawMainNavLinkItem(resolveReferences: { maxDepth: 20 })
      }
    }
  }
`;

export default SuccessFactors;
