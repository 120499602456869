import generateRandomId from "./generateRandomId";

const createBreadcrumbs = (content) => {
  const arr = [];

  const checkParent = (parentContent) => {
    if(parentContent.parent) {
      checkParent(parentContent.parent.tabs.content);
    }
    arr.push({
      _key: generateRandomId(),
      label: parentContent.title,
      url: createPath(parentContent),
    });
  }

  const createPath = (childContent, path) => {
    let actualPath = path || `/${childContent.slug.current}`;
    if (childContent.parent) {
      const parentContent = childContent.parent.tabs.content;
      actualPath = `/${parentContent.slug.current}${actualPath}`;
      if (parentContent.parent) {
        actualPath = createPath(parentContent, actualPath);
      }
    }
    return actualPath;
  }

  arr.push({
    _key: generateRandomId(),
    label: 'Home',
    url: '/',
  });

  if (content.parent) {
    checkParent(content.parent.tabs.content);
  }

  arr.push({
    _key: generateRandomId(),
    label: content.title,
    url: createPath(content),
  });

  return arr;
}

export default createBreadcrumbs;